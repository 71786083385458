<template>
  <div class="contenido">
    <b-modal v-model="isModalActive" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal>
      <template>
        <div class="container-modal container">
          <div class="parte-superior">
            <div>
              <span>
                <font-awesome-icon class="icono-modal white" :icon="['fal', 'lightbulb-on']" />
                <span class="title-m bold white">{{
                  $t("COMPETITION.STIMULUS_LOWER")
                }}</span>
                <span v-if="estimulosCreativos" class="date-stimulus title-m white">
                  {{ estimulosCreativos[indexSelectedEstimulo].fecha_inicio.split('T')[0] }}
                </span>
                <font-awesome-icon v-if="estimulosCreativos && estimulosCreativos.length !== 1" class="icono-modal white"
                  style="cursor: pointer;" :icon="['far', 'chevron-left']"
                  @click="indexSelectedEstimulo = (indexSelectedEstimulo - 1) < 0 ? estimulosCreativos.length - 1 : indexSelectedEstimulo - 1" />
                <font-awesome-icon v-if="estimulosCreativos && estimulosCreativos.length !== 1" class="icono-modal white"
                  style="cursor: pointer;" :icon="['far', 'chevron-right']"
                  @click="indexSelectedEstimulo = (indexSelectedEstimulo + 1) > estimulosCreativos.length - 1 ? 0 : indexSelectedEstimulo + 1" />
              </span>
            </div>
            <div>
              <button class="btn-modal btn button" @click="isModalActive = false">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'times']" />
                  <span class="text-l white">{{
                    $t("COMPETITION.CLOSE_STIMULUS")
                  }}</span>
                </span>
              </button>
            </div>
          </div>
          <div class="stimulus text-l gray">
            <div class="estimulo" v-if="estimulosCreativos !== null">
              <span class="title-stimulus">{{ estimulosCreativos[indexSelectedEstimulo].titulo }}</span>
              <p>
                <img :src="'data:image;base64,' + estimulosCreativos[indexSelectedEstimulo].file_data"
                  :title="estimulosCreativos[indexSelectedEstimulo].orig_filename" alt="" />
              </p>
            </div>
            <!-- <div
              class="estimulo"
              v-if="estimulosCreativos"
            >
              <span class="title-stimulus">{{estimulosCreativos[indexSelectedEstimulo].titulo}}</span>
              <p>
                {{ estimulosCreativos[indexSelectedEstimulo].redaccion }}
              </p>
            </div> -->
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="isModalSurveyActive" has-modal-card trap-focus :destroy-on-hide="false" :can-cancel="false"
      aria-role="dialog" aria-modal>
      <template>
        <div class="container-modal container">
          <div class="parte-superior">
            <div>
              <span><font-awesome-icon class="icono-modal white" :icon="['fal', 'poll']" />
                <span class="title-m bold white">{{
                  $t("SATISFACTION_SURVEY.TITLE")
                }}</span></span>
            </div>
          </div>
          <div class="stimulus text-l gray" v-if="questions.length > 0 && responses.respuestas.length > 0">
            <div class="question py-3" v-for="(question, index) in questions" :key="index">
              <p class="has-text-weight-semibold"><span>{{ question.orden }}. </span>{{ question.pregunta }}</p>
              <div class="pt-3">
                <b-checkbox v-model="surveySelectedOptions" :name="question.id.toString()"
                  native-value="Ansiedad ecológica">
                  {{ $t("EVALUATOR.SURVEY_PHASE_1.RESPONSE_1") }}
                </b-checkbox>
              </div>
              <div class="pt-3">
                <b-checkbox v-model="surveySelectedOptions" :name="question.id.toString()"
                  native-value="Redes sociales">
                  {{ $t("EVALUATOR.SURVEY_PHASE_1.RESPONSE_2") }}
                </b-checkbox>
              </div>
              <div class="pt-3">
                <b-checkbox v-model="surveySelectedOptions" :name="question.id.toString()"
                  native-value="Preocupación social">
                  {{ $t("EVALUATOR.SURVEY_PHASE_1.RESPONSE_3") }}
                </b-checkbox>
              </div>
              <div class="pt-3">
                <b-checkbox v-model="surveySelectedOptions" :name="question.id.toString()"
                  native-value="Contenido distópico">
                  {{ $t("EVALUATOR.SURVEY_PHASE_1.RESPONSE_4") }}
                </b-checkbox>
              </div>
              <div class="pt-3">
                <b-checkbox v-model="surveySelectedOptions" :name="question.id.toString()"
                  native-value="Otro">
                  {{ $t("EVALUATOR.SURVEY_PHASE_1.RESPONSE_5") }}:
                </b-checkbox>
                <b-field class="pt-3">
                  <b-input custom-class="custom-input-background-gray" v-if="surveySelectedOptions.includes('Otro')" name="question.id.toString()" v-model.trim="otherResponse"></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <div class="parte-inferior">
            <div>
              <button class="btn-modal btn button" @click="sendSurvey()" :disabled="checkIfSurveyIsAnswered">
                <span>
                  <font-awesome-icon class="icono" :icon="['fal', 'paper-plane']" />
                  <span class="text-l white">{{
                    $t("STIMULUS.send")
                  }}</span></span>
              </button>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <ImageHeader :title="$t('EVALUATOR.TITILE')" />
    <ModalMessageBox :type="modalAttributes.type" :title="modalAttributes.title" :subtitle="modalAttributes.subtitle" />
    <div class="is-10 container-data container">
      <div class="btn-finish-container container">
        <div class="is-3 is-offset-9 column has-text-right">
          <button class="btn-more-evaluates btn button" @click="goToOtrosEvaluadores()">
            <span>
              <font-awesome-icon class="icono" :icon="['fal', 'user-friends']" />
              <span class="text-l black">{{
                $t("EVALUATOR.SEE_ALL")
              }}</span>
            </span>
          </button>
        </div>
      </div>
      <div class="container is-10 data">
        <div class="columns personal-data">
          <div class="is-6 is-offset-1 column has-text-left">
            <p style="margin-bottom: 18px; cursor: pointer;" @click="goToSelector">
              <font-awesome-icon style="margin-right: 10px;" :icon="['fal', 'arrow-left']" /> {{$t("EVALUATOR.BACK_TO_SELECTION")}}
            </p>
            <p class="title-m black bold" style="margin-bottom: 15px;">
              {{ user.nombre }} {{ user.primer_apellido }}
              {{ user.segundo_apellido }}
            </p>
            <div class="text-m info-user">
              <span class="info-lit">{{ $t("MANAGER.PHASE", { faseNum: 1 }) }}</span>
              <span class="info-lit"><span class="icon-with-lit">
                  <font-awesome-icon :icon="['fal', 'globe']" /> {{ userEval.nombre }}
                </span></span>
              <span class="info-lit selector-zona bold black custom-select">
                <span class="icon-with-lit">
                  <font-awesome-icon :icon="['fal', 'map-marker-alt']" /> {{ $t("STIMULUS.DATA.PROVINCE") }}: {{ selectProvincia }}
                </span>
              </span>
            </div>
          </div>
          <div class="is-3 is-offset-1 column has-text-right">
            <ProgressBar :noLiterals="false" :value="numEvaluated" :total="numTotal" />
          </div>
        </div>
        <div class="columns selectors">
          <div class="is-4 is-offset-1 column has-text-left custom-select">
            <b-field>
              <b-select :placeholder="$t('EVALUATOR.ALL')" v-model="selectFilter" @input="changeFilter">
                <option value="T">{{ $t("EVALUATOR.ALL") }}</option>
                <option value="P">{{ $t("EVALUATOR.PENDIENTES") }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="is-2 is-offset-1 column has-text-left">
            <button class="btn button" @click="isModalActive = true;">
              <span>
                <font-awesome-icon class="icono" :icon="['fal', 'lightbulb-on']" />
                <span class="text-m black">{{
                  $t("COMPETITION.STIMULUS")
                }}</span>
              </span>
            </button>
          </div>
          <div class="is-2 is-offset-1 column has-text-centered custom-select">
            <p class="title-m white bold">
              {{ $t("EVALUATOR.TASKS", { tasksNum: evaluaciones.length }) }}
            </p>
            <p class="text-m white download-relatos" @click="downloadRelatos()" style="padding-top: 5px">
              <font-awesome-icon :icon="['fal', 'print']" />
              {{ $t("EVALUATOR.PRINT") }}
            </p>
          </div>
        </div>
        <div class="columns" v-if="!finalizado">
          <div class="column is-10 is-offset-1">
            <p class="message-order" v-html="$t('EVALUATOR.MESSAGE_REMAINING_FASE_1')">
            </p>
          </div>
        </div>
        <div class="columns">
          <div class="column is-10 is-offset-1">
            <table class="table">
              <thead>
                <tr>
                  <th v-for="(column, index) in columns" :key="index" scope="col" :class="{
                    'has-text-left': column.leftTitle,
                    'has-text-centered': column.centerTitle,
                    'has-text-right': column.rightTitle,
                    'puntuacion': index === columns.length - 1
                  }">
                    <b class="text-m">{{ $t(column.title) }}</b>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in evaluaciones" :key="index" class="text-m gray bold"
                  :class="{ 'block-row': item.nota !== null }">
                  <td class="has-text-centered contracte-field" style="padding-top: 2.6em;"
                    @click="goToRelatoDetail(item, index)">
                    {{ item.relato_id }}
                  </td>
                  <td class="has-text-left" style="padding-top: 2.6em;" @click="goToRelatoDetail(item, index)">
                    <span v-if="
                      item.necesidades_especiales && item.necesidades_especiales.length > 0
                    "> <span v-for="(necesidad, index) in item.necesidades_especiales" :key="index"><b-tag type="is-warning" rounded>{{ necesidad }}</b-tag></span></span>
                    <span v-if="
                      item.titulo &&
                      item.titulo !== null &&
                      item.titulo !== ''
                    ">{{ item.titulo }}</span>
                    <span v-if="item.titulo === null || item.titulo === ''"><em>{{
                      $t("EVALUATOR.RELATO_W_T") }}</em></span>
                  </td>
                  <td class="has-text-right">
                    <div v-if="!finalizado">
                      <div class="icon-mark liked" :class="{ 'item-liked': item.nota === 'A' }"
                        @click="evaluarRelato(item, 'A')">
                        <span class="has-text-centered like-btn" :class="{ 'item-liked': item.nota === 'A' }">
                          <font-awesome-icon :icon="['fal', 'heart']" />
                        </span>
                      </div>
                      <div class="icon-mark unliked" :class="{ 'item-un-liked': item.nota === 'B' }"
                        @click="evaluarRelato(item, 'B')">
                        <span class="has-text-centered un-like-btn" :class="{ 'item-un-liked': item.nota === 'B' }">
                          <font-awesome-icon :icon="['fal', 'times']" />
                        </span>
                      </div>
                    </div>
                    <div v-if="finalizado">
                      <div class="icon-mark liked item-liked" v-if="item.nota === 'A'">
                        <span class="has-text-centered like-btn" :class="{ 'item-liked': item.nota === 'A' }">
                          <font-awesome-icon :icon="['fal', 'heart']" />
                        </span>
                      </div>
                      <div class="icon-mark unliked item-un-liked" v-if="item.nota === 'B'">
                        <span class="has-text-centered un-like-btn" :class="{ 'item-un-liked': item.nota === 'B' }">
                          <font-awesome-icon :icon="['fal', 'times']" />
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="btn-finish-container columns" v-if="!finalizado">
        <div class="is-3 is-offset-9 column has-text-right">
          <button class="btn-finish button" :disabled="numEvaluated < evaluaciones.length || numA !== 3"
            @click="finalizarEvaluaciones()">
            <span class="text-l bold white">{{$t("EVALUATOR.FINISH_PROVINCE")}}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageHeader from "@/components/ImageHeader.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import ModalMessageBox from "@/components/ModalMessageBox.vue";
import { FASES } from "../constants.js";

export default {
  name: "evaluador",
  components: {
    ImageHeader,
    ProgressBar,
    ModalMessageBox,
  },
  data() {
    return {
      modalAttributes: {
        type: "",
        title: "",
        subtitle: "",
      },
      numTotal: 0,
      numEvaluated: 0,
      numA: 0,
      selectFilter: "T",
      selectProvincia: "",
      columns: [
        {
          title: "EVALUATOR.NUMBER",
          centerTitle: true,
        },
        {
          title: "EVALUATOR.TITLE_WORK",
          leftTitle: true,
        },
        {
          title: "EVALUATOR.MARK",
          rightTitle: true,
        },
      ],
      fase: FASES.EVALUACION_1,
      evaluaciones: [],
      projects: {},
      user: {},
      finalizado: true,
      userEval: {},
      userEvals: [],
      isModalActive: false,
      estimulosCreativos: null,
      indexSelectedEstimulo: 0,
      isModalSurveyActive: false,
      questions: [],
      responses: {
        respuestas: []
      },
      selectedSurveyOption: '',
      surveySelectedOptions: [],
      otherResponse: ''
    };
  },
  created() {
    this.$store.commit("setShowSpinner", true);

    if(this.$route.params.provincia) {
      this.selectProvincia = this.$route.params.provincia;
    } else {
      this.goToSelector();
    }

    this.$store
      .dispatch("getUser")
      .then((user) => {
        // this.setModalCompleted();
        this.user = user;
        this.$store
          .dispatch("getEstimulosByIdioma", {
            idioma: this.$i18n.locale,
          })
          .then((estimulos) => {
            this.estimulosCreativos = estimulos;
          });
        this.$store
          .dispatch("getUsuarioEvaluacion", {
            idioma: this.$i18n.locale,
            fase: this.fase,
          })
          .then((usuario_evaluacion) => {
            this.userEval = usuario_evaluacion[0];
            if (usuario_evaluacion.length > 0) {
              this.finalizado = usuario_evaluacion.find(eE => eE.provincia === this.selectProvincia).finalizado;
              this.$store.commit("setUsuarioEvaluaciones", usuario_evaluacion);
            }
            this.$store
              .dispatch("getEvaluaciones", {
                fase: this.fase,
                provincia: this.selectProvincia,
                idioma: this.$i18n.locale
              })
              .then((evaluaciones) => {
                evaluaciones = evaluaciones.map(obj => {
                  if (obj.necesidades_especiales) {
                    obj.necesidades_especiales = obj.necesidades_especiales.split(',');
                  } else {
                    obj.necesidades_especiales = [];
                  }
                  return obj;
                });
                this.$store.commit("setShowSpinner", false);
                this.numEvaluated = evaluaciones.filter(
                  (obj) => obj.nota !== null
                ).length;
                this.numA = evaluaciones.filter((obj) => obj.nota === "A").length;
                this.numTotal = evaluaciones.length;
                this.$store.commit("setAllEvaluaciones", evaluaciones);
                this.evaluaciones = evaluaciones;
              })
              .catch((error) => {
                this.$store.commit("setShowSpinner", false);
              });
          });
      })
      .catch((error) => {
        this.$store.commit("setShowSpinner", false);
      });
  },
  methods: {
    goToSelector() {
      this.$router.push({ name: 'seleccion-provincia'});
    },
    goToOtrosEvaluadores() {
      this.$router.push({
        name: "otros-evaluadores",
        params: { provincia: this.selectProvincia, fase: this.fase },
      });
    },
    changeFilter() {
      if (this.selectFilter === "T") {
        this.evaluaciones = this.$store.state.evaluadores.allEvaluaciones;
      } else if (this.selectFilter === "P") {
        this.evaluaciones = this.evaluaciones.filter(
          (obj) => obj.nota === null
        );
      }
    },
    goToRelatoDetail(evaluacion, index) {
      if (!this.finalizado) {
        this.$store.commit("setEvaluacionSlide", {
          index,
          evaluaciones: this.evaluaciones,
        });
        this.$router.push({
          name: "relato",
          params: { relatoId: evaluacion.relato_id, usuarioId: this.user.id },
        });
      }
    },
    evaluarRelato(evaluacion, nota) {
      if (evaluacion.nota === null) {
        this.postEvaluacion(evaluacion, nota);
      } else {
        this.$buefy.dialog.confirm({
          title: this.$t("EVALUATOR.MODIFICAR_TITLE"),
          message: this.$t("EVALUATOR.MODIFICAR_TEXT"),
          confirmText: this.$t("EVALUATOR.MODIFICAR_BUTTON"),
          cancelText: this.$t("COMPETITION.CANCELAR"),
          type: "is-danger",
          hasIcon: true,
          iconPack: "far",
          onConfirm: () => {
            this.postEvaluacion(evaluacion, nota);
          },
        });
      }
    },
    finalizarEvaluaciones() {
      this.$buefy.dialog.confirm({
        title: this.$t("EVALUATOR.FINISH_TITLE"),
        message: this.$t("EVALUATOR.FINISH_TEXT"),
        confirmText: this.$t("COMPETITION.FINISH_LOWER"),
        cancelText: this.$t("COMPETITION.CANCELAR"),
        type: "is-danger",
        hasIcon: true,
        iconPack: "far",
        onConfirm: () => {
          this.$store
            .dispatch("finalizarEvaluaciones", {
              idioma: this.$i18n.locale,
              fase: FASES.EVALUACION_1,
              provincia: this.selectProvincia,
              email: this.user.email
            })
            .then(async (res) => {
              this.finalizado = true;
              this.$store.state.evaluadores.usuarioEvaluaciones.find(uE => uE.provincia === this.selectProvincia).finalizado = true;
              const response = await this.$store.dispatch("getPreguntas", { codigoEncuesta: 'EVAL_FASE_1', idioma: this.$i18n.locale });
              for (let question of response) {
                this.responses.respuestas.push({ pregunta_id: question.id, respuesta: "" });
              }
              this.questions = response;
              this.goToSelector();
              //this.isModalSurveyActive = true;
            });
        },
      });
    },
    sendSurvey() {
      this.$store.commit("setShowSpinner", true);
      const index = this.surveySelectedOptions.findIndex(obj => obj === 'Otro');
      if (index !== -1 && this.otherResponse !== '') {
        this.surveySelectedOptions[index] = this.otherResponse;
      }
      const responsesSends = {
        respuestas: []
      };
      for (let optionSelect of this.surveySelectedOptions) {
        responsesSends.respuestas.push({ pregunta_id: this.responses.respuestas[0].pregunta_id, respuesta: optionSelect });
      }
      this.$store.dispatch("responderEncuesta", { encuestaSatisfaccion: responsesSends }).then(response => {
        this.isModalSurveyActive = false;
        this.goToSelector();
        this.$store.commit("setShowSpinner", false);
      }).catch(error => {
        this.$store.commit("setShowSpinner", false);
      });
    },
    postEvaluacion(evaluacion, nota) {
      this.$store
        .dispatch("evaluarRelato", {
          relato_id: evaluacion.relato_id,
          nota,
          posicion: null,
          fase: FASES.EVALUACION_1,
        })
        .then((res) => {
          if (evaluacion.nota === null) {
            this.numEvaluated++;
          } else {
            if (evaluacion.nota === "A") {
              this.numA--;
            }
          }
          if (nota === "A") {
            this.numA++;
          }
          // if (this.numEvaluated === this.evaluaciones.length) {
          //   this.setModalWarningEvaluacion();
          // }
          evaluacion.nota = nota;
          if (this.selectFilter === "P") {
            const indexEval = this.evaluaciones.findIndex(
              (obj) => obj.relato_id === evaluacion.relato_id
            );
            this.evaluaciones.splice(indexEval, 1);
          }
        });
    },
    downloadRelatos() {
      this.$store.commit("setShowSpinner", true);
      this.$store.dispatch("downloadRelatos", {
        fase: FASES.EVALUACION_1,
        provincia: this.selectProvincia
      }).then((response) => {
        const file = new Blob([response], {
          type: "application/pdf",
        });
        this.$store.commit("setShowSpinner", false);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(file);
        link.download = `relatos.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    setModalCompleted() {
      this.modalAttributes = {
        type: "warning",
        title: "Modificando un relato ya evaluado",
        subtitle: "",
      };
      this.$store.commit("setActiveModalCompleted", true);
    },
    setModalWarningEvaluacion() {
      this.modalAttributes = {
        type: "success",
        title: "Has evaluado todos tus relatos",
        subtitle:
          "Antes de finalizar tus evaluaciones, puedes revisarlas. Una vez hecho, finaliza con el botón 'Calificación terminada'.",
      };
      this.$store.commit("setActiveModalCompleted", true);
    }
  },
  computed: {
    checkIfSurveyIsAnswered() {
      return this.surveySelectedOptions.filter(obj => obj !== '').length < 3;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";

.personal-data {
  padding-top: 25px;
}

.selectors {
  background-color: $primary;
  margin-left: 0px;
  margin-right: 0px;
}

.container-data {
  margin-top: -180px;
  padding-bottom: 100px;
}

.container.data {
  background-color: white;
}

.titulo-seccion {
  margin-top: 10px;
  margin-bottom: 16px;

  display: flex;
  align-items: center;

  div {
    min-width: fit-content;
  }

  div:last-child {
    width: -webkit-fill-available;
    margin-left: 5%;
    height: 20px;
  }
}

hr {
  margin: 0.1rem 0;
}

.table {
  width: 100%;
  margin-bottom: 25px;
  border-collapse: separate;
  border-spacing: 0px 12px;
}

.table td,
.table th {
  border: none;
  padding: 1em 1.25em;
}

.table tbody tr:hover {
  cursor: pointer;
}

.table tbody tr {
  background-color: $background-box;
  height: 60px;

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
}

.icon-mark {
  background-color: white;
  padding: 20px;
  width: 60px;
  display: inline-grid;
  margin: 5px;
  border-radius: 50%;
  font-size: 20px;

  .like-btn {
    color: $green;
  }
}

.icon-mark.liked:hover {
  color: white;
  background-color: $green;

  .like-btn {
    color: white;
  }
}

.icon-mark.unliked:hover {
  color: white;
  background-color: $primary;

  .un-like-btn {
    color: white;
  }
}

.item-liked {
  color: white !important;
  background-color: $green;
}

.un-like-btn {
  color: $primary;
}

.item-un-liked {
  color: white !important;
  background-color: $primary;
}

.block-row {
  font-weight: 100 !important;
  color: rgb(150, 150, 150) !important;
}

.btn-finish-container {
  position: relative;
  top: 10px;
}

.btn-finish {
  background-color: $primary;
  color: white;
  height: 60px;
  border-radius: 10px;
  // margin-bottom: 30px;
  margin-top: 10px;
  width: 100%;
}

.btn-finish:hover {
  color: white;
}

.btn {
  background-color: white;
  height: 50px;
  border-radius: 30px;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

  // width: 100%;
  .content-button {
    display: flex;
    align-items: center;
  }

  .icono {
    font-size: 20px;
    color: $primary;
    margin-right: 10px;
  }
}

.btn-more-evaluates {
  margin-top: -50px;
  background-color: white;
  border-radius: 20px;

  .icono {
    color: $primary;
    margin-right: 15px;
  }
}

.puntuacion {
  padding-right: 53px !important;
}

.download-relatos {
  cursor: pointer;
}

.info-user {
  .info-lit {
    padding-right: 8px;
  }

  display: flex;
  align-items: center;

  .selector-zona {
    display: flex;
    align-items: center;
  }
}

.icono-modal {
  font-size: 25px;
  margin-right: 20px;
}

.btn-modal {
  background-color: $primary;

  .icono {
    color: white;
  }
}

.stimulus {
  padding: 40px;
  background-color: white;
  margin-top: 25px;
  border-radius: 10px;
}

.parte-superior {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-stimulus {
  font-weight: bold;
  font-size: 22px;
}

.date-stimulus {
  margin-left: 15px;
  margin-right: 15px;
}

.icono-modal {
  font-size: 25px;
  margin-right: 20px;
}

.btn-modal {
  background-color: $primary;

  .icono {
    color: white;
  }
}

.parte-superior {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.parte-inferior {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.parte-superior.welcome {
  justify-content: flex-end;
}

.stimulus {
  padding: 40px;
  background-color: white;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 10px;
}

.b-radio.radio input[type=radio]+.check:before {
  background-color: $primary !important;
}

.message-order {
  padding-top: 20px;
  font-size: 1.2em;
}

.tag {
  margin-right: 8px;
}
</style>